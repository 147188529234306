import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getNominations() {
    return axiosIns.get('/waiter/nominations')
  },
  createNomination({
    name,
    sellingPrice,
    color,
    vendibleTaggingsAttributes = [],
    vendingContributionTriggerAttributes = {},
  }) {
    const formData = serialize(snakecaseKeys({
      nomination: {
        name,
        sellingPrice,
        color,
        vendibleTaggingsAttributes,
        vendingContributionTriggerAttributes,
      },
    }))

    return axiosIns.post('/waiter/nominations', formData)
  },
  updateNomination({
    id,
    name,
    sellingPrice,
    color,
    vendibleTaggingsAttributes = [],
    vendingContributionTriggerAttributes = {},
  }) {
    const formData = serialize(snakecaseKeys({
      nomination: {
        name,
        sellingPrice,
        color,
        vendibleTaggingsAttributes,
        vendingContributionTriggerAttributes,
      },
    }))

    return axiosIns.put(`/waiter/nominations/${id}`, formData)
  },
  deleteNomination(id) {
    return axiosIns.delete(`/waiter/nominations/${id}`)
  },
  updatePositions(idAndPositions) {
    const formData = serialize(snakecaseKeys({
      nominations: idAndPositions,
    }))

    return axiosIns.put('/waiter/nominations/update_positions', formData)
  },
}
