<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              新規売上計上トリガー
            </v-card-title>

            <v-card-text>
              <v-select
                :value="currentForm"
                :items="triggerableItems"
                :item-value="item => {
                  if (item.attributes) return {
                    triggerableId: item.id,
                    triggerableType: `${item.type[0].toUpperCase()}${item.type.slice(1)}`,
                  }
                }"
                :value-comparator="(a, b) => {
                  if (a && b) return +a.triggerableId === +b.triggerableId && a.triggerableType === b.triggerableType
                }"
                item-text="attributes.name"
                label="売上計上トリガー"
                persistent-placeholder
                no-data-text="選択可能なデータがありません"
                :menu-props="{ auto: true, offsetY: true }"
                @change="
                  currentForm.triggerableId = $event.triggerableId;
                  currentForm.triggerableType = $event.triggerableType"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                :disabled="!isValid"
                :loading="isSubmitting"
                @click="create"
              >
                作成
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                :ripple="false"
                @click="cancelForm"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="JSON.stringify(vendingContributionTriggers)"
        :headers="headers"
        :items="vendingContributionTriggers"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        group-by="attributes.triggerableType"
        no-data-text="データがありません"
      >
        <template #[`group.header`]="{ groupBy, group }">
          <td :colspan="headers.length">
            <strong v-if="groupBy[0] === 'attributes.triggerableType'">
              {{
                {
                  Nomination: currentClub.nominationAlias,
                  Referral: currentClub.referralAlias,
                }[group]
              }}
            </strong>
          </td>
        </template>

        <template #[`item.actions`]="{item}">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
} from '@mdi/js'
import {
  reject,
  groupBy,
  find,
  findIndex,
  isEmpty,
} from 'lodash'
import VendingContributionTriggerApi from '@/api/waiter/VendingContributionTrigger'
import ReferralApi from '@/api/waiter/Referral'
import NominationApi from '@/api/waiter/Nomination'
import useCompInit from '@/views/composable/useCompInit'
import useCurrentData from '@/views/composable/useCurrentData'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const { currentClub } = useCurrentData()
    const isSubmitting = ref(false)
    const isFormOpen = ref(false)
    const currentForm = ref({
      triggerableId: null,
      triggerableType: null,
    })
    const vendingContributionTriggers = ref([])
    const referrals = ref([])
    const nominations = ref([])

    const triggerableItems = computed(() => {
      const groupedTriggers = groupBy(vendingContributionTriggers.value, 'attributes.triggerableType')

      const unusedNominations = reject(nominations.value, nomination => {
        return find(groupedTriggers.Nomination, o => +o.attributes.triggerableId === +nomination.id)
      })

      const unusedReferrals = reject(referrals.value, referral => {
        return find(groupedTriggers.Referral, o => +o.attributes.triggerableId === +referral.id)
      })

      return [
        ...isEmpty(unusedNominations)
          ? []
          : [
            { header: currentClub.value.nominationAlias },
            ...unusedNominations,
          ],
        ...isEmpty(unusedReferrals)
          ? []
          : [
            { header: currentClub.value.referralAlias },
            ...unusedReferrals,
          ],
      ]
    })

    const isValid = computed(() => {
      return !!currentForm.value.triggerableId && !!currentForm.value.triggerableType
    })

    const cancelForm = () => {
      isFormOpen.value = false

      isSubmitting.value = false

      currentForm.value = {
        triggerableId: null,
        triggerableType: null,
      }
    }

    const create = async () => {
      isSubmitting.value = true
      const { triggerableId, triggerableType } = currentForm.value
      const res = await VendingContributionTriggerApi.createVendingContributionTrigger({ triggerableId, triggerableType })

      if (res?.data) {
        vendingContributionTriggers.value.push(res.data.vendingContributionTrigger.data)
        vm.$toast.success('売上計上トリガーを作成しました')
      }

      cancelForm()
    }

    const destroy = async id => {
      const res = await VendingContributionTriggerApi.deleteVendingContributionTrigger(id)

      if (res) {
        const index = findIndex(vendingContributionTriggers.value, o => +o.id === +id)
        vendingContributionTriggers.value.splice(index, 1)
        vm.$toast.success('売上計上トリガーを削除しました')
      }
    }

    const getVendingContributionTriggers = async () => {
      isLoading.value = true

      const res = await VendingContributionTriggerApi.getVendingContributionTriggers()

      if (res?.data) {
        vendingContributionTriggers.value = [...res.data.vendingContributionTriggers.data]
      }

      isLoading.value = false
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.data) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    const getNominations = async () => {
      const res = await NominationApi.getNominations()

      if (res?.data) {
        nominations.value = [...res.data.nominations.data]
      }
    }

    initWith([
      getVendingContributionTriggers(),
      getReferrals(),
      getNominations(),
    ])

    return {
      // data
      isLoading,
      isFormOpen,
      isSubmitting,
      currentForm,
      vendingContributionTriggers,
      referrals,
      nominations,
      headers: [
        { text: 'ターゲット', value: 'meta.name' },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],

      // computed
      isValid,
      triggerableItems,

      // methods
      cancelForm,
      create,
      destroy,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
      },
    }
  },
}
</script>
